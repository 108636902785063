import React from "react"
import { graphql } from "gatsby"
import { Layout, Head as HeadComponent } from "@bw/layouts"
import { SidebarLayout, Subpages } from "@bw/partials"
import { useTranslation } from "react-i18next"
import { Header, Content } from "@bw/modules/mapModules"

export const Head = ({ location, pageContext, data }) => (
  <HeadComponent
    title={data.page.metaTitle || data.page.title}
    image={data.page.image?.localImage?.childImageSharp?.original?.src}
    location={location}
    pageContext={pageContext}
    description={data.page.metaDescription}
    doNotIndex={data.page.doNotIndex}
  />
)

const PageTemplate = ({ data, pageContext }) => {
  const { t } = useTranslation()
  const { page, parents, siblings, children } = data

  /**
   * Generate the breadcrumbs
   */
  const breadcrumbsLinks = React.useMemo(() => {
    const links = [
      {
        title: t("homepage"),
        to: "/",
      },
    ]

    if (pageContext.hasParents && parents?.nodes) {
      parents.nodes.forEach(bread => links.push(bread))
    }

    links.push({
      title: page.title,
      to: page.url,
    })

    return links
  }, [t, pageContext, parents, page])

  /**
   * ensure sorting of siblings, parents and children
   */
  if (pageContext.hasSiblings && siblings?.nodes) {
    siblings.nodes.sort(
      (a, b) =>
        pageContext.siblings.indexOf(a.id) - pageContext.siblings.indexOf(b.id)
    )
  }
  if (pageContext.hasParents && parents?.nodes) {
    parents.nodes.sort(
      (a, b) =>
        pageContext.parents.indexOf(a.id) - pageContext.parents.indexOf(b.id)
    )
  }
  if (pageContext.hasChildren && children?.nodes) {
    children.nodes.sort(
      (a, b) =>
        pageContext.children.indexOf(a.id) - pageContext.children.indexOf(b.id)
    )
  }

  /*
   * Loading the Contents modules, ex Content or CallToAction, TeasersLatest
   */
  const contents = <Content modules={page.content} />

  return (
    <Layout {...{ pageContext }}>
      {/*
       * Loading the Header module, ex Hero or PageHeader
       */}
      <Header
        modules={page.header}
        page={page}
        pageContext={pageContext}
        breadcrumbsLinks={breadcrumbsLinks}
      />

      {pageContext.hasSiblings && <Subpages tabs={siblings.nodes} />}

      {pageContext.hasChildren &&
      children.nodes &&
      children.nodes.length > 0 ? (
        <SidebarLayout sidemenu={children.nodes}>{contents}</SidebarLayout>
      ) : (
        contents
      )}
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query pageQuery(
    $id: String!
    $siblings: [String]
    $hasSiblings: Boolean = false
    $parents: [String]
    $hasParents: Boolean = false
    $children: [String]
    $hasChildren: Boolean = false
  ) {
    parents: allCollectionPages(filter: { id: { in: $parents } }) {
      nodes @include(if: $hasParents) {
        id
        to: slug
        title
      }
    }

    siblings: allCollectionPages(filter: { id: { in: $siblings } }) {
      nodes @include(if: $hasSiblings) {
        id
        to: slug
        title
      }
    }

    children: allCollectionPages(filter: { id: { in: $children } }) {
      nodes @include(if: $hasChildren) {
        id
        to: slug
        title
      }
    }

    page: collectionPages(id: { eq: $id }) {
      url
      title
      metaTitle
      metaDescription
      image {
        localImage {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
      doNotIndex

      teaserImage {
        url
      }
      header {
        __typename
        suptitle
        title
        subtitle

        ...HeroModuleFragment
        ...HeroCarouselModuleFragment
      }
      content {
        __typename
        suptitle
        title
        subtitle
        backgroundVariant
        buttonType {
          value
        }
        buttonUrl
        buttonIsExternal
        buttonText
        buttonPosition {
          value
        }
        buttonEntry {
          url
        }
        idAttribute
        classAttribute

        ...CallToActionModuleFragment
        ...ContentModuleFragment
        ...ContentWithImageModuleFragment
        ...GalleryModuleFragment
        ...ContentOnColumnsModuleFragment
        ...DownloadsModuleFragment
        ...TeasersManualModuleFragment
        ...LogoGridModuleFragment
        ...ImageModuleFragment
        ...InstagramModuleFragment
        ...VideoModuleFragment
        ...FormModuleFragment
        ...TimelineModuleFragment
        ...ListOfPoisModuleFragment
        ...ListOfArticlesModuleFragment
        ...ListOfProfilesModuleFragment
        ...ListOfJobsModuleFragment
        ...ListOfEventsModuleFragment
        ...RemarkableTopicsModuleFragment
        ...PredefinedModuleModuleFragment
      }
    }
  }
`
